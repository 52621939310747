import { Tenant, TenantConfig } from './hooks/TenantConfig'
import { lightTheme, amerilifeTheme } from '@leadrilla/pulsar'
import {
  demoTheme,
  pipelineTheme,
  edmTheme,
  trkingTheme,
  godspeedColors,
  godspeedTheme,
  advocateTheme,
  advocateColors,
  pmgTheme,
  pmgColors,
  consumeraffairsColors,
  consumeraffairsTheme,
  leadprodigyColors,
  leadprodigyTheme,
  adplColors,
  adplTheme,
  mfgTheme,
  mfgColors,
  hfgTheme,
} from './styles/themes'

const leadrillaConfig: TenantConfig = {
  tenant: 'leadrilla',
  name: 'Leadrilla',
  title: 'Leadrilla - Lead Generation Platform',
  description:
    'High quality, exclusive, on-demand leads. Sign up today and boost your sales performance instantly. Start receiving leads in less than 10 minutes.',
  themeColor: lightTheme.brand.action,
  theme: lightTheme,
  mapTheme: {
    0.2: '#4F77D8',
    0.4: '#69AD78',
    0.6: '#F4BA61',
    0.8: '#D95656',
  },
  support_email: 'hello@leadrilla.com',
  call_campaigns: {
    ipp_idle_timeout: 600_000,
  },
  signup: {
    invite_only: false,
    collect_npn: false,
    collect_industry: true,
    collect_company: true,
  },
  terms: {
    company_name: 'Leadrilla Inc',
    site: 'https://leadrilla.com',
    address: '401 W Main St Suite 303 Lexington, KY 40507',
  },
  referral_program: {
    enabled: true,
    referrer_amount: 50_00,
    referee_amount: 25_00,
    required_spend_amount: 200_00,
  },
  call_campaign_video_id: 'ISmWlDxLVH4',
  enable_lead_print: true,
  voice_enabled: false,
  teams: { enabled: false },
  organizations: {
    enabled: true,
    collect_during_signup: false,
    display_name: 'Organization',
  },
}

const amerilifeConfig: TenantConfig = {
  tenant: 'amerilife',
  name: 'LeadStar Marketplace',
  title: 'LeadStar Marketplace - Lead Generation Platform',
  description: 'High quality, exclusive, on-demand leads.',
  themeColor: amerilifeTheme.brand.action,
  theme: amerilifeTheme,
  mapTheme: {
    0.2: '#4F77D8',
    0.4: amerilifeTheme.brand.action,
    0.6: '#F4BA61',
    0.8: '#D95656',
  },
  connected_stripe_account_id: 'acct_1LT95OHBnL00edTK',
  support_email: 'support@leadstarhub.com',
  call_campaigns: {
    ipp_idle_timeout: 1800_000,
  },
  signup: {
    invite_only: false,
    collect_npn: true,
    collect_industry: false,
    collect_company: false,
  },
  terms: {
    company_name: 'Leadrilla Inc',
    site: 'https://marketplace.leadstarhub.com',
    address: '401 W Main St Suite 303 Lexington, KY 40507',
  },
  referral_program: {
    enabled: false,
  },
  call_campaign_video_id: 'ISmWlDxLVH4',
  enable_lead_print: true,
  voice_enabled: false,
  teams: { enabled: true },
  organizations: { enabled: false },
}

const demoConfig: TenantConfig = {
  tenant: 'demo',
  name: 'SalesRiver',
  title: 'All-in-One Sales Platform for Distributed Teams - SalesRiver',
  description:
    'The first all-in-one sales platform for distributed teams. Acquire, route, sell & manage.',
  themeColor: '#F72585',
  theme: demoTheme,
  mapTheme: {
    0.2: '#0000FF',
    0.4: '#00FF00',
    0.6: '#FFA500',
    0.8: '#FF0000',
  },
  support_email: 'hello@salesriver.com',
  call_campaigns: {
    ipp_idle_timeout: 1800_000,
  },
  signup: {
    invite_only: false,
    collect_npn: true,
    collect_industry: false,
    collect_company: false,
  },
  terms: {
    company_name: 'SalesRiver, Inc',
    site: 'https://demo.salesriver.com',
    address: '401 W Main St Suite 303 Lexington, KY 40507',
  },
  subscription: { enabled: false },
  referral_program: {
    enabled: false,
  },
  voice_enabled: false,
  teams: { enabled: true },
  organizations: {
    enabled: true,
    collect_during_signup: false,
    display_name: 'Organization',
  },
}

const hfgConfig: TenantConfig = {
  tenant: 'hfg',
  name: 'Heartland Financial Group',
  title: 'All-in-One Sales Platform for Distributed Teams',
  description:
    'The first all-in-one sales platform for distributed teams. Acquire, route, sell & manage.',
  themeColor: '#F72585',
  theme: hfgTheme,
  mapTheme: {
    0.2: '#0000FF',
    0.4: '#00FF00',
    0.6: '#FFA500',
    0.8: '#FF0000',
  },
  support_email: 'hello@salesriver.com',
  call_campaigns: {
    ipp_idle_timeout: 1800_000,
  },
  signup: {
    invite_only: false,
    collect_npn: true,
    collect_industry: false,
    collect_company: false,
  },
  terms: {
    company_name: 'SalesRiver, Inc',
    site: 'https://demo.salesriver.com',
    address: '401 W Main St Suite 303 Lexington, KY 40507',
  },
  subscription: { enabled: false },
  referral_program: {
    enabled: false,
  },
  voice_enabled: false,
  teams: { enabled: true },
  organizations: {
    enabled: true,
    collect_during_signup: false,
    display_name: 'Organization',
  },
}

const pipelineConfig: TenantConfig = {
  tenant: 'pipeline',
  name: 'PipeLine Leads',
  title: 'PipeLine Leads',
  description: 'High quality, on-demand leads.',
  themeColor: pipelineTheme.brand.action,
  theme: pipelineTheme,
  mapTheme: {
    0.2: '#4F77D8',
    0.4: '#69AD78',
    0.6: '#F4BA61',
    0.8: '#D95656',
  },
  connected_stripe_account_id: 'acct_1LcYIvGVVlTZMK6a',
  support_email: 'support@pipelineleads.co',
  call_campaigns: {
    ipp_idle_timeout: 1800_000,
  },
  disableMarketplace: true,
  signup: {
    invite_only: false,
    collect_npn: false,
    collect_industry: false,
    collect_company: false,
  },
  terms: {
    company_name: 'PipeLine Leads',
    site: 'https://dashboard.pipelineleads.co',
    address: '15019 Purple Martin St. Winter Garden FL 34787',
  },
  referral_program: {
    enabled: false,
  },
  call_campaign_video_id: 'k3bGD4Csl2U',
  voice_enabled: false,
  teams: { enabled: false },
  organizations: {
    enabled: true,
    collect_during_signup: false,
    display_name: 'agency',
  },
}

const edmConfig: TenantConfig = {
  tenant: 'edm',
  name: 'EDM Marketplace',
  title: 'EDM Marketplace',
  description: 'High quality, on-demand leads.',
  themeColor: edmTheme.brand.action,
  theme: edmTheme,
  mapTheme: {
    0.2: '#4F77D8',
    0.4: '#69AD78',
    0.6: '#F4BA61',
    0.8: '#D95656',
  },
  connected_stripe_account_id: 'acct_1MP7c9FsCGn1PMkE',
  support_email: 'marketplace@edmleadnetwork.com',
  call_campaigns: {
    ipp_idle_timeout: 1800_000,
  },
  signup: {
    invite_only: true,
    collect_npn: false,
    collect_industry: false,
    collect_company: false,
  },
  terms: {
    company_name: 'EDM Lead Network',
    site: 'https://marketplace.edmleadnetwork.com',
    address: '1166 E. Warner Rd. #218 Gilbert, AZ 85296',
  },
  logos: {
    dashboard_main: {
      height: '48px',
      width: '120px',
    },
  },
  referral_program: {
    enabled: false,
  },
  voice_enabled: false,
  teams: { enabled: false },
  organizations: {
    enabled: true,
    collect_during_signup: false,
    display_name: 'group',
  },
}

const trkingConfig: TenantConfig = {
  tenant: 'trking',
  name: 'LeadStream Marketplace',
  title: 'LeadStream Marketplace',
  description: 'High quality, exclusive, on-demand leads.',
  themeColor: trkingTheme.brand.action,
  theme: trkingTheme,
  mapTheme: {
    0.2: '#4F77D8',
    0.4: '#69AD78',
    0.6: '#F4BA61',
    0.8: '#D95656',
  },
  //connected_stripe_account_id: 'acct_1CT9s1Lc4Ws1KbsE',
  support_email: 'leadstreamsupport@trkingim.com',
  call_campaigns: {
    ipp_idle_timeout: 1800_000,
  },
  signup: {
    invite_only: false,
    collect_npn: true,
    collect_industry: false,
    collect_company: false,
  },
  terms: {
    company_name: 'TR King Insurance Marketing',
    site: 'https://leadstream.trkingim.com',
    address: '7445 McConnell Road, Roanoke, Virginia, 24019',
  },
  logos: {
    dashboard_main: {
      height: '48px',
      width: '120px',
    },
  },
  referral_program: {
    enabled: true,
    referrer_amount: 50_00,
    referee_amount: 25_00,
    required_spend_amount: 200_00,
  },
  custom_menu_links: [
    {
      text: 'Quoting Tool',
      icon: 'tools-tt',
      href: 'https://www.insurancetoolsportal.com/leadstream/auth/signin',
    },
  ],
  voice_enabled: false,
  subscription: { enabled: true },
  teams: { enabled: false },
  organizations: {
    enabled: true,
    collect_during_signup: false,
    display_name: 'Organization',
  },
}

const godspeedConfig: TenantConfig = {
  tenant: 'godspeed',
  name: 'The Cave',
  title: 'The Cave',
  description: 'Unearth Opportunities, Close Deals.',
  themeColor: godspeedColors.actionStandard,
  theme: godspeedTheme,
  mapTheme: {
    0.2: '#0000FF',
    0.4: '#00FF00',
    0.6: '#FFA500',
    0.8: '#FF0000',
  },
  support_email: 'leads@thisisgodspeed.com',
  call_campaigns: {
    ipp_idle_timeout: 1800_000,
  },
  signup: {
    invite_only: true,
    collect_npn: false,
    collect_industry: false,
    collect_company: false,
  },
  terms: {
    company_name: 'GODSPEED Financial',
    site: 'https://leads.thisisgodspeed.com/',
    address: '3708 Old Orchard Court, Celina, TX 75009',
  },
  subscription: { enabled: true },
  referral_program: {
    enabled: false,
  },
  disableMarketplace: false,
  voice_enabled: false,
  teams: { enabled: false },
  organizations: {
    enabled: true,
    collect_during_signup: false,
    display_name: 'Organization',
  },
}

const advocateConfig: TenantConfig = {
  tenant: 'advocate',
  name: 'Advocate LeadPro',
  title: 'Advocate LeadPro',
  description: 'Harness the Power of Advocate Financial: Drive Leads, Close Sales, Thrive!',
  themeColor: advocateColors.actionStandard,
  theme: advocateTheme,
  mapTheme: {
    0.2: '#0000FF',
    0.4: '#00FF00',
    0.6: '#FFA500',
    0.8: '#FF0000',
  },
  support_email: 'lead.support@myadvocatefinancial.com',
  call_campaigns: {
    ipp_idle_timeout: 1800_000,
  },
  signup: {
    invite_only: false,
    collect_npn: true,
    collect_industry: false,
    collect_company: false,
  },
  terms: {
    company_name: 'Advocate Financial',
    site: 'https://advocateleadpro.com/',
    address: '9604 Coldwater Rd Suite 205, Fort Wayne, IN 46825',
  },
  referral_program: {
    enabled: false,
  },
  voice_enabled: false,
  subscription: { enabled: true },
  teams: { enabled: false },
  organizations: {
    enabled: true,
    collect_during_signup: false,
    display_name: 'Agency',
  },
}

const consumeraffairsConfig: TenantConfig = {
  tenant: 'consumeraffairs',
  name: 'ConsumerAffairs',
  title: 'ConsumerAffairs - Powered by SalesRiver',
  description: 'ConsumerAffairs - Powered by SalesRiver',
  themeColor: consumeraffairsColors.actionStandard,
  theme: consumeraffairsTheme,
  mapTheme: {
    0.2: '#0000FF',
    0.4: '#00FF00',
    0.6: '#FFA500',
    0.8: '#FF0000',
  },
  support_email: 'leadsupport@consumeraffairs.com',
  call_campaigns: {
    ipp_idle_timeout: 1800_000,
  },
  signup: {
    invite_only: true,
    collect_npn: false,
    collect_industry: false,
    collect_company: false,
  },
  terms: {
    company_name:
      'Consumers Unified, LLC d/b/a ConsumerAffairs.com, Nevada Limited Liability Company (LLC)',
    site: 'https://marketplace.consumeraffairs.com',
    address: '600 East 4th Street, Tulsa, Oklahoma 74120',
  },
  referral_program: {
    enabled: false,
  },
  voice_enabled: false,
  disableMarketplace: true,
  extra_terms: {
    name: 'Agent Contract',
    url: 'https://lr-pubic-assets.s3.amazonaws.com/ConsumerAffairs_Lead+Agreement_SR.pdf',
  },
  teams: { enabled: false },
  hide_statewide_targeting: true,
  hide_radius_targeting: true,
  welcome_message:
    'Grow your sales pipeline with a Campaign! Campaigns offer real-time, semi-exclusive leads as well as calls directly to your phone from interested consumers. Use our CRM tools to stay on top of your leads. Our platform is easy to use and ready to help you succeed.',
  sms_checkbox: true,
  organizations: {
    enabled: true,
    collect_during_signup: false,
    display_name: 'Company',
  },
}

const leadprodigyConfig: TenantConfig = {
  tenant: 'leadprodigy',
  name: 'LeadMart',
  title: 'LeadMart - By Lead Prodigy',
  description: 'LeadMart - By Lead Prodigy',
  themeColor: leadprodigyColors.actionStandard,
  theme: leadprodigyTheme,
  mapTheme: {
    0.2: '#0000FF',
    0.4: '#00FF00',
    0.6: '#FFA500',
    0.8: '#FF0000',
  },
  support_email: 'support@leadprodigy.net',
  call_campaigns: {
    ipp_idle_timeout: 1800_000,
  },
  signup: {
    invite_only: false,
    collect_npn: false,
    collect_industry: false,
    collect_company: false,
  },
  terms: {
    company_name: 'Deft Automated Solutions, LLC',
    site: 'http://leadmart.leadprodigy.com/',
    address: '171 Noddy Rd. Buda, TX 78610',
  },
  referral_program: {
    enabled: true,
    referrer_amount: 0,
    referee_amount: 0,
    required_spend_amount: 0,
    link_only: true,
  },
  voice_enabled: false,
  subscription: { enabled: true },
  disableMarketplace: false,
  teams: { enabled: false },
  organizations: {
    enabled: true,
    collect_during_signup: false,
    display_name: 'Organization',
  },
}

const adplConfig: TenantConfig = {
  tenant: 'adpl',
  name: 'Agent Direct Power Leads',
  title: 'Agent Direct Power Leads - Powered by SalesRiver',
  description: 'Agent Direct Power Leads - Powered by SalesRiver',
  themeColor: adplColors.actionStandard,
  theme: adplTheme,
  mapTheme: {
    0.2: '#0000FF',
    0.4: '#00FF00',
    0.6: '#FFA500',
    0.8: '#FF0000',
  },
  support_email: 'techsupport@agentdirectpowerleads.com',
  call_campaigns: {
    ipp_idle_timeout: 1800_000,
  },
  signup: {
    invite_only: true,
    collect_npn: false,
    collect_industry: false,
    collect_company: false,
  },
  terms: {
    company_name: 'MS Powermail',
    site: 'https://agentdirectpowerleads.com/',
    address: '2050 Reevestone Road, Richmond IN 47374',
  },
  referral_program: {
    enabled: false,
  },
  voice_enabled: false,
  subscription: { enabled: true },
  disableMarketplace: true,
  // connected_stripe_account_id: 'acct_1NsmpzCAXRPUhNod',
  teams: { enabled: false },
  organizations: {
    enabled: true,
    collect_during_signup: false,
    display_name: 'Agency',
  },
}

const mfgConfig: TenantConfig = {
  tenant: 'mfg',
  name: 'MYMFG Marketplace',
  title: 'MYMFG Marketplace',
  description: 'MYMFG Marketplace - Powered by SalesRiver',
  themeColor: mfgColors.actionStandard,
  theme: mfgTheme,
  mapTheme: {
    0.2: '#0000FF',
    0.4: '#00FF00',
    0.6: '#FFA500',
    0.8: '#FF0000',
  },
  support_email: 'mymfgmarketplace@messerfinancial.com',
  call_campaigns: {
    ipp_idle_timeout: 1800_000,
  },
  signup: {
    invite_only: false,
    collect_npn: false,
    collect_industry: false,
    collect_company: false,
  },
  terms: {
    company_name: 'Messer Financial Group, Inc',
    site: 'https://marketplace.messerfinancial.com/',
    address: '8100 Tower Point Dr, Charlotte, North Carolina 28227',
  },
  referral_program: {
    enabled: false,
  },
  voice_enabled: false,
  subscription: { enabled: true },
  disableMarketplace: true,
  teams: { enabled: false },
  organizations: {
    enabled: true,
    collect_during_signup: false,
    display_name: 'Agency',
  },
}

const pmgConfig: TenantConfig = {
  tenant: 'pmg',
  name: 'PMG Marketplace',
  title: 'PMG Marketplace - Lead Generation Platform',
  description: 'PMG Lead Marketplace - Powered by SalesRiver',
  themeColor: pmgColors.actionStandard,
  theme: pmgTheme,
  mapTheme: {
    0.2: '#0000FF',
    0.4: '#00FF00',
    0.6: '#FFA500',
    0.8: '#FF0000',
  },
  support_email: 'support@pmgleadmarketplace.com',
  call_campaigns: {
    ipp_idle_timeout: 1800_000,
  },
  signup: {
    invite_only: false,
    collect_npn: false,
    collect_industry: false,
    collect_company: false,
  },
  terms: {
    company_name: 'PMG IMO',
    site: 'https://pmgleadmarketplace.com/',
    address: '4901 LBJ Freeway Suite 101, Dallas, TX 75244',
  },
  referral_program: {
    enabled: false,
  },
  voice_enabled: false,
  subscription: { enabled: true },
  disableMarketplace: true,
  organizations: {
    enabled: true,
    collect_during_signup: false,
    display_name: 'Agency',
  },
  teams: { enabled: false },
}

const config: { [key in Tenant]: TenantConfig } = {
  leadrilla: leadrillaConfig,
  amerilife: amerilifeConfig,
  amerilife_test: {
    ...amerilifeConfig,
    tenant: 'amerilife_test',
    connected_stripe_account_id: undefined,
  },
  demo: demoConfig,
  hfg: hfgConfig,
  pipeline: pipelineConfig,
  edm: edmConfig,
  trking: trkingConfig,
  godspeed: godspeedConfig,
  advocate: advocateConfig,
  pmg: pmgConfig,
  consumeraffairs: consumeraffairsConfig,
  leadprodigy: leadprodigyConfig,
  adpl: adplConfig,
  mfg: mfgConfig,
}

export default config
